<template>
  <component
    :is="props.tag"
    ref="sliderItemRef"
    class="st-slider-item"
    @click="selectHandler"
  >
    <slot :selected="isSelected" />
  </component>
</template>

<script lang="ts" setup>
import type { SliderItemsApi } from './types'

interface Props {
  tag?: 'div' | 'li'
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
})

const api: SliderItemsApi | undefined = inject('StSliderItemsApi')
const sliderItemRef = ref<HTMLElement | null>(null)

const instanceKey = computed(
  () => getCurrentInstance()?.proxy?.$.vnode.key as string,
)
const tabIndex = computed<number | undefined>(() =>
  sliderItemRef.value
    ? api?.items?.value?.indexOf(sliderItemRef.value)
    : undefined,
)
const isSelected = computed<boolean | undefined>(
  () =>
    api?.selectedItems.value.includes(
      instanceKey.value || tabIndex.value || -1,
    ),
)

function selectHandler() {
  api?.selectItemHandler(instanceKey.value || tabIndex.value || -1)
}

onMounted(() => {
  if (sliderItemRef.value) {
    api?.registerItem(sliderItemRef.value)
  } else {
    console.error("WlTab couldn't register")
  }
})

onBeforeUnmount(() => {
  if (sliderItemRef.value) {
    api?.unregisterItem(sliderItemRef.value)
  } else {
    console.error("WlTab couldn't unregister")
  }
})
</script>

<style scoped>
.st-tab {
  cursor: pointer;
}
</style>
